import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import './style.css'

const scene = new THREE.Scene()
const cam = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000 )
cam.position.z = 5
cam.position.y = 2

const renderer = new THREE.WebGLRenderer()
renderer.setSize(window.innerWidth, window.innerHeight)
document.body.appendChild(renderer.domElement)

const spotLight = new THREE.SpotLight( 0xffffff, 20 )
spotLight.position.set( 0, 3, 1 )
spotLight.castShadow = true
scene.add( spotLight )

const button = document.getElementById('katanaOut')

const retultText = document.getElementById('resultText')

const manCont = document.querySelector('.manualTwo')

const retry = document.getElementById('retry')

function loadModel(modelPath) {
  return new Promise((resolve, reject) => {
    const loader = new GLTFLoader()
    loader.load(modelPath, (gltf) => {
      resolve(gltf.scene)}, undefined, (error) => {
        reject(error)
      })
    })
  }

const modelPath = 'models/rock_sword.glb'
let katana
let sword

loadModel(modelPath)
  .then((loadedModel) => {
    katana = loadedModel
    sword = katana.children[1]
    let stone = katana.children[0]
    stone.receiveShadow = true
    sword.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true
      }
    })
    scene.add(katana)
    console.info('Model: ', katana)
    console.info('Model2: ', sword)
  })
  .catch((error) => {
    console.error('Error loading the model:', error)
  })

let animInProgress = false

function animateSword() {
  if (!animInProgress && sword) {
    animInProgress = true
    const targetPosition = sword.position.y + 0.8
    const animDur = 1000
    const startTime = Date.now()

    const animationLoop = () => {
      const currentTime = Date.now()
      const elapsed = currentTime - startTime

      if (elapsed < animDur) {
        const progress = elapsed / animDur
        sword.position.y = THREE.MathUtils.lerp(sword.position.y, targetPosition, progress)
        requestAnimationFrame(animationLoop)
      } else {
        sword.position.y = targetPosition
        
        const targetRotation = sword.rotation.y + Math.PI / 2
        const rotationDuration = 1000
        const rotationStartTime = Date.now()

        const rotationAnimation = () => {
          const rotationCurrentTime = Date.now()
          const rotationElapsed = rotationCurrentTime - rotationStartTime
          if (rotationElapsed < rotationDuration) {
            const rotationProgress = rotationElapsed / rotationDuration
            sword.rotation.y = THREE.MathUtils.lerp(sword.rotation.y, targetRotation, rotationProgress)
            requestAnimationFrame(rotationAnimation)
          } else {
            sword.rotation.y = targetRotation
            animInProgress = false
          }
        }
        rotationAnimation()
      }
    }
    animationLoop()
  }
}

button.addEventListener('click', (e) => {
  e.preventDefault()
  manCont.classList.add('hidden')
  if (random() === 1) {
    animateSword()
    retultText.innerText = 'You win!'
  }
  else {
    retultText.innerText = 'You loose'
  }
  button.classList.add('hidden')
  retry.classList.remove('hidden')
  retry.addEventListener('click', (e) => {
    window.location.reload()
  })
})

const controls = new OrbitControls(cam, renderer.domElement)

controls.update()

const random = () => {
  return Math.floor(Math.random() * 2)
}

console.log(random())

renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap

const animate = () => {
  requestAnimationFrame(animate)
  controls.update()
  renderer.render(scene, cam)
}

animate()

